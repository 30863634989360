<template>
	<div class="legal">
		<TransitionGroup name="fade" appear>
			<h2 :key="1">Privacy Policy</h2>
			<p :key="2" class="updated">Last updated 2022-08-26</p>
		</TransitionGroup>

		<TransitionGroup appear @before-enter="beforeEnter" @enter="enter">
			<p :key="3" :data-index="1">
				At Kwizz, accessible from <a href="https://kwizz.io">https://kwizz.io</a>, one of your main
				priorities is the privacy of our visitors. This Privacy Policy document contains types of
				information that is collected and recorded by Kwizz and how we use it. If you have
				additional questions or require more information about our Privacy Policy, do not hesitate
				to contact us. This Privacy Policy applies only to our online activities and is valid for
				visitors to our website with regards to the information that they shared and/or collect in
				Kwizz. This policy is not applicable to any information collected offline or via channels
				other than this website.
			</p>

			<h3 :key="4" :data-index="2">Consent</h3>
			<p :key="5" :data-index="2">
				By using our website, you hereby consent to our Privacy Policy and agree to its terms. For
				our Terms and Conditions, please visit <router-link to="/terms">this page</router-link>.
			</p>

			<h3 :key="6" :data-index="3">Information we collect</h3>
			<p :key="7" :data-index="3">
				The personal information that you are asked to provide, and the reasons why you are asked to
				provide it, will be made clear to you at the point we ask you to provide your personal
				information. If you contact us directly, we may recieve additional information about you
				such as your name, email address, phone number, the contents of the message and/or
				attachments that you may send us, and any other information you may choose to provide. When
				you register for an Account, we may ask for your contact information, including items such
				as name and email adress.
			</p>

			<h3 :key="8" :data-index="4">How we use your information</h3>
			<p :key="9" :data-index="4">
				We use the information we collect in various ways, including to:
			</p>
			<ul :key="10" :data-index="5">
				<li>Provide, operate, and maintain our website</li>
				<li>Improve, personalize, and expand your website</li>
				<li>Understand and analyze how you use our website</li>
				<li>Develop new products, services, features, and functionality</li>
				<li>
					Communicate with you, including for customer service, to provide you with updates and
					other information relating to the website
				</li>
			</ul>

			<h3 :key="11" :data-index="6">Log Files</h3>
			<p :key="12" :data-index="6">
				Kwizz follows a standard procedure of using log files. These files log visitors when they
				visit websites. All hosting companies do this and a part of hosting services' analytics. The
				information collected by log files include internet protocol (IP) addresses, browser type,
				Internet Service Provider (ISP), date and time stamp, referring/exit pages, and possibly the
				number of clicks. These are not linked to any information that is personally identifiable.
				The purpose of the information is for analyzing trends, administering the site, tracking
				users' movement on the website, and gathering demographic information.
			</p>

			<h3 :key="13" :data-index="7">GDPR Data Protection Rights</h3>
			<p :key="14" :data-index="7">
				We would like to make sure you are fully aware of all your data protection rights. Every
				user is entitled to the following:
			</p>
			<ul :key="15" :data-index="8">
				<li>
					The right to access &ndash; You have the right to request copies of your personal data.
				</li>
				<li>
					The right to rectification &ndash; You have the right to request that we correct any
					information you believe is inaccurate. You also have the right to request that we complete
					the information you believe is incomplete.
				</li>
				<li>
					The right to erasure &ndash; You have the right to request that we erase your personal
					data, under certain conditions.
				</li>
				<li>
					The right to restrict processing &ndash; You have the right to request that we restrict
					the processing of your personal data, under certain conditions.
				</li>
				<li>
					The right to object to processing &ndash; You have the right to object to our processing
					of your personal data, under certain conditions.
				</li>
				<li>
					The right to data portability &ndash; You have the right to request that we transfer the
					data that we have collected to another organization, or directly to you, under certain
					conditions.
				</li>
			</ul>
			<p :key="16" :data-index="9">
				If you make a request, we have one month to respond to you. If you would like to exercise
				any of these rights, please contact us.
			</p>

			<p :key="17" class="contact" :data-index="10">
				If you have any questions or need more information about the privacy policy, please contact
				me at <a href="mailto:hello@emilcarlsson.se">hello@emilcarlsson.se</a>.
			</p>
		</TransitionGroup>
	</div>
</template>

<script>
import gsap from 'gsap'

export default {
	methods: {
		beforeEnter(el) {
			el.style.opacity = 0
			el.style.transform = 'translateY(30px)'
		},

		enter(el, done) {
			gsap.to(el, {
				opacity: 1,
				y: 0,
				duration: 0.8,
				onComplete: done,
				delay: el.dataset.index * 0.1,
			})
		},
	},
}
</script>

<style lang="scss" scoped></style>
